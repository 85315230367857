export const getOperations = (property) => {
    if(property?.operations){
        const operationsArray = [];
        property.operations.map((operation) => {
            operationsArray.push(operation.operation_type)
        })
        return operationsArray;
    }
    return ['']
}

export const getType = (property) => {
    if(property?.type){
        return property.type.name
    }
    return ''
} 

export const getEnvironment = (property) => {
    return property?.room_amount;
}

export const getSurface = (property) => {
    let surface = 0
    if(property?.type){
        if(property.type.name.toLowerCase() === 'terreno'){
            surface = property.surface
        }
        else{
            surface = property.total_surface
        }
        if(surface > 0) return surface?.split(".")[0] + " " + property.surface_measurement
    }
    return null
}

export const getRooms = (propert) => {
    return propert?.suite_amount;
}

export const getSurfaces = (property) => {
    const surfacesArray = {
        roofed : property.roofed_surface,
        unroofed : property.unroofed_surface,
        semiroofed_surface : property.semiroofed_surface,
        surface : property.surface,
        total_surface : property.total_surface,
        measurement : property.surface_measurement
    }
    return surfacesArray;
} 

export const getLocation = (property) => {
    if(property?.location){
        return property?.location.name
    }
    return ''
}

export const getFakeAddres = (property) => {
    return property?.fake_address
}

export const getRealAddress = (property) => {
    return property?.real_address
}

export const getPrices = (property) => {
    if(property?.operations){

        const pricesArray = []
        
        property.operations.map((operation) => {
            if(operation.prices.length === 0){return []}
            const obj = {}
            obj.type = operation?.operation_type
            obj.price = !property?.web_price ? "Precio a consultar" : operation?.prices[0].price
            obj.currency = !property?.web_price ? " " : operation.prices[0]?.currency
            pricesArray.push(obj)
        })
        
        return pricesArray;
    }
    return []
} 

export const getTags = (property) => {
    if(property?.tags){
        return property.tags
    }
    return []
}

export const getCustomTags = (property) => {
    if(property?.custom_tags){
        return property.custom_tags
    }
    return []
}

export const hasGarage = (property) => {
    return property?.parking_amount ? true : false
}

export const makeLink = (property) => {
    return "/propiedad/" + getOperations(property)[0]?.toLowerCase().replaceAll(" ","-") + '/' + property?.id
}

export const makeTextAmenities = (property) => {
    let text = ""
    // Ambientes
    text += property.room_amount ? property.room_amount + " ambiente" : ''
    text += property.room_amount > 1 ? 's / ' : ' / '
    // Baños
    text += " " + property.bathroom_amount ? property.bathroom_amount + " baño" : ''
    text += property.bathroom_amount > 1 ? 's / ' : ' / '
    // Dormitorios
    text += " " + property.suite_amount ? property.suite_amount + " dormitorio" : ''
    text += property.suite_amount > 1 ? 's / ' : ' / '
    // Cochera
    text += hasGarage(property) ? " cochera" : ''
    return text
}

export const makeTextSurfaces = (property) => {
    let text = ""
    // Total
    text += " " + property.total_surface > 0 ? property.total_surface + " m2 totales / " : ''
    // Roofed
    text += property.roofed_surface > 0 ? property.roofed_surface + " m2 cubiertos / " : ''
    // Semi-Roofed
    text += property.semiroofed_surface > 0 ? property.semiroofed_surface + " m2 semi-cubiertos / " : ''
    // Unroofed
    text += " " + property.unroofed_surface > 0 ? property.unroofed_surface + " m2 descubiertos / " : ''
    // Terreno
    text += " " + property.surface > 0 ? property.surface + " m2 terreno /" : ''

    return text
}

export const makeTextTags = (property) => {
    let text = ""
    if(property.tags){
        let index = 0
        while(index < 4 && index < property.tags.length){
            text += property.tags[index].name + " / "
            index ++;
        }
    }
    return text
}

export const getBluePrints = (property) => {
    if(property.photos){
        return property.photos.filter(photo => photo.is_blueprint === true)
    }
    return []
}

export const getTour = (property) => {
    if(property?.videos){
        return property.videos.find(video => video.provider === 'matterport' || video.provider === 'hauzd' || video.provider === '360magictour' || video.provider === 'vista360' || video.provider === 'roundme')
    }
    return undefined
}

export const getLastVideo = (property) => {
    if(property.videos){
        const list_videos = property.videos.filter(video => video.provider === 'youtube' || video.provider === 'vimeo')
        return list_videos[list_videos.length - 1]
    }
    return undefined
}
export const getTitle = (property) => {
    if(property?.publication_title){
        return property.publication_title;
    }
    return 'Sin titulo'
}