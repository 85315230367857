import React from 'react';
import { Link } from "gatsby";

const Breadcrumbs = props =>{
    return (
        <div className={`breadcrumb-div ${props.skeleton && ' skeleton '}`}>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    {props.props.map((item, index) =>(
                        <li className={'breadcrumb-item'} key={index}> 
                            {(item.active 
                                ? item.name 
                                : <Link to={item.route}>{item.name}</Link>)} 
                        </li>
                    ))}
                </ol>
            </nav>
        </div>
    )
}

export default Breadcrumbs