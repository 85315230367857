import React from "react"

import { getName, getStatus } from "../../helpers/helper.developments"
import { getCover, getQuality } from "../../helpers/helper.rendering"

const DevShulbiusCard = (props) => {

    const {development,skeleton,dark} = props

    const makeUrlUnities = (unities) => {
        let url = ''
        unities.forEach((unity, index) => {
            url += `${unity.id}`
            if(index !== unities.length - 1) {
                url += `-`
            }
        })
        return url
    }

    return (
        <a target="__blank" href={"/emprendimientos/" + development?.id + (props.unities ? '?unidades=' + makeUrlUnities(props.unities) : '')} class="col-lg-4">
            <div class={"proyecto " + (skeleton && ' skeleton ') + (dark && ' dark ')}>
                <img src={development && getQuality(getCover(development.photos))} class="thumb"/>
                <div class="opacity" />
                <div class="centra-principal">
                    <div class="centra-base">
                        <div class="centra text-center">
                            <div class="shulbius">
                                <img class="logo" src="https://bellagamba.com/svg/shulbius.svg" alt="Shulbius"/>
                            </div>
                            <div class="emprendimiento">
                                {development && getName(development)}</div>
                        </div>
                    </div>
                </div>
                <div class="bottom-end">
                    {development && getStatus(development)}</div>
            </div>
        </a>
    )
}

export default DevShulbiusCard 