import React,{useEffect, useState} from 'react'
import Development from '../development-card'
import DevShulbiusCard from '../Shulbius/devshulbiuscard'
import Loading from '../Loading'
import { connect } from 'react-redux'
import {getDevelopmentsFilterAction, updateFiltersDevelopment} from '../../redux/developmentsDucks'
import { getFilterReverseDevs } from '../../helpers/helper.developments'
import { useLocation } from "@reach/router";

import Shulibus_Skeleton from '../Skeletons/dev-shulbius'

const Developments = ({developmentsFilters,loading,api_key,dispatch}) => {

    const [filter,setFilter] = useState(0)
    const [buttons,setButtos] = useState([
        {
            name:"VER TODOS",
            value:0
        },
        {
            name:"EN POZO",
            value:3
        },
        {
            name:"EN CONSTRUCCIÓN",
            value:4
        },
        {
            name:"TERMINADOS",
            value:6
        },

    ])

    useEffect(() => {
        // console.log(api_key)
        if(api_key){
            dispatch(updateFiltersDevelopment(filter))
            dispatch(getDevelopmentsFilterAction()) // Get all developments
        }
    },[filter,api_key])

    const location = useLocation();
    const isShulbius = location.pathname.includes('/shulbius');

    const validateLength = (filteredDevs) =>
        (filteredDevs.length <= 0)
            ? getFilterReverseDevs(developmentsFilters.data.objects)
            : filteredDevs

    const renderDevsCards = () => {
        if (!isShulbius) {
            return (
                
                <div className="container-fluid">
                        <div class="grid-container">
                        {
                            // false
                            developmentsFilters.data && !loading 
                            ?   getFilterReverseDevs(developmentsFilters.data.objects)
                                .map((development,index) => (
                                    <Development key={index} development={development} />
                                    ))
                            :   [1,2,3,4].map((element,index) => (<Development index={index} skeleton={true} />))
                        }
                        </div>
                    </div>
                
            ) 
        }
        if (isShulbius) {
            return (
                <div className="container-fluid shulbius-cards">
                    <div class="row">
                    {
                        developmentsFilters.data && !loading
                            ? validateLength(getFilterReverseDevs(developmentsFilters.data.objects, true)).map((development,index) => (
                                <DevShulbiusCard key={index} development={development} /> ))
                            :   [1,2,3,4].map((element,index) => (<DevShulbiusCard index={index} dark={true} skeleton={true} />))
                    }
                    </div>
                </div>
                )
        }
    }

    return  (
        <section id="featured-devs" class="pt-lg-5">
            <div className="container-fluid my-5 pb-lg-3">
                <div className="d-flex flex-wrap justify-content-center">
                    {buttons.map((button,index) => (
                        <button key={index} className={'button-filter mb-2 mb-lg-0 ' + (filter === button.value ? ' active ' : '')} onClick={() => setFilter(button.value)}>{button.name}</button>
                    ))}
                </div>
            </div>
            { renderDevsCards() }
        </section>
    )
}
export default connect(state => ({
    developmentsFilters: state.developments.developmentsFilters,
    loading: state.developments.loading,
    api_key: state.settings.keys.tokko
}),null)(Developments);