import React from "react";
import Developments from './developments'
import Breadcrumbs from "../breadcrumbs";
import { graphql, useStaticQuery } from 'gatsby';

export default function About() {

    const { realEstate } = useStaticQuery(graphql`
        query {
            realEstate {
                name
                sections {
                    developments {
                        about {
                            title
                        }
                    }
                }
            }
        }`
    )

    const mockRealEstate = {
        sections: {
            developments: {
                about: {
                    subtitle: "Contamos con una amplia variedad de emprendimientos, cada uno pensado y diseñado para los diferentes estilos de vida."
                }
            }
        }
    }

    return (
        <section class="development-about">
            <div class="container-fluid">
                <div class="row">
                    <div className="col-lg-12">
                        <Breadcrumbs
                            props={[
                            { name: "Home", route: "/", location: "" },
                            { name: "Emprendimientos", route:   "", location: ""},
                            ]}
                        />
                    </div>
                    <div class="col-lg-12">
                        <h2>{realEstate.sections.developments.about.title}</h2>
                        <h4>{mockRealEstate.sections.developments.about.subtitle}</h4>
                    </div>
                </div>
            </div>
            <Developments />
        </section>
    )
}
