import * as React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

//Components
import About from '../../components/Emprendimientos/about'
import MainDevelopment from "../../components/Emprendimientos/maindevelopment"
import BannerTop from "../../components/Modules/MediaPush/BannerTop"
import Modules from "../../components/modules"

const IndexPage = () => (
  <Layout>
    <Seo title="Emprendimientos" description="Aquí debería ir la descripción de la sección de emprendimientos" />
    <BannerTop page="emprendimientos"/>
    <MainDevelopment/>  
    <About />
  </Layout>
)

export default IndexPage
