import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'gatsby'
import OwlCarousel from 'react-owl-carousel3'
import { connect } from 'react-redux'
import { getDevelopmentsAction} from '../../redux/developmentsDucks'
import { getCover, getQuality, scrollToElement } from '../../helpers/helper.rendering';
import { getLastFeatured, getFilterReverseDevs } from '../../helpers/helper.developments'
import IconRightSvg from "../../images/svg/icon-right.svg"
import { useLocation } from "@reach/router";
import Loading from '../Loading'

const options = {
    loop: true,
    smartSpeed: 1000,
    stagePadding: 0,
    margin: 0,
    responsive: {
        0: {
            items: 1,
        }
    },
}

const MainDevelopment = ({properties, developments, loading, api_key, dispatch}) => {

    const slider = useRef()
    const location = useLocation()
    const [shulbiusDevelopments,setShulbiusDevelopments] = useState([])
    const [loaded,setLoaded] = useState(false)
    useEffect(() => {
        if(api_key){
            dispatch(getDevelopmentsAction())
        }
    }, [api_key])

    useEffect(() => {
        setShulbiusDevelopments((getFilterReverseDevs(developments?.data?.objects, true)))
        setLoaded(true)
    },[developments])

    const mockRealEstate = {
        sections: {
            home: {
                mainDev: {
                    title: "NUESTROS EMPRENDIMIENTOS",
                    subtitle: "SHULBIUS DREAM",
                    location: "Hipólito Yrigoyen al 2400, Florida, Vicente López",
                    text: "Diseño de vanguardia con materiales de primera calidad.",
                    btn: {
                        link: "/emprendimiento/hipolito-yrigoyen-al-2400-florida/22660",
                        text: "Conocé más"
                    },
                    link: {
                        to: "/emprendimientos",
                        text: "Ver Todos"
                    }
                }
            }
        }
    }

    const mockRealEstateEmprendimiento = {
        sections: {
            emprendimientos: {
                mainDev: {
                    title: "COLECCIÓN SHULBIUS BY BELLAGAMBA",
                    subtitle: "Espacios pensados para disfrutar.",
                    img: {
                        src: "https://bellagamba.com/svg/shulbius.svg",
                        alt: "Shulbius by Bellagamba"
                    },
                    text: "Tu hogar es el único lugar en el mundo donde las cosas son como vos querés que sean. Y eso es Shulbius, la combinación perfecta entre diseño, lugar, ambiente, comodidades y vos. Una experiencia de vida donde se cumplen todas tus expectativas.",
                    link: {
                        to: "/shulbius",
                        text: "Conocé más"
                    }
                }
            }
        }
    }

    return !shulbiusDevelopments?.length > 0 || loading && !loaded ? <Loading absolute={true} /> : (
        <section id="shulbius" class="about-us">
            <div class="container-fluid px-0">
                <div class="row no-gutters">
                    <div class="col-lg-4 d-flex align-items-center justify-content-left">
                        <div class="half-container px-4">
                            <h5>{location.pathname.includes('/emprendimientos') 
                                ? mockRealEstateEmprendimiento.sections.emprendimientos.mainDev.title
                                : mockRealEstate.sections.home.mainDev.title}
                            </h5>
                                {!location.pathname.includes('/emprendimientos') 
                                ? <h3>{mockRealEstate.sections.home.mainDev.subtitle}</h3> 
                                : ''}
                            {location.pathname.includes('/emprendimientos') 
                            ?   <>
                                    <img className="img-logo" src={mockRealEstateEmprendimiento.sections.emprendimientos.mainDev.img.src}
                                        alt={mockRealEstateEmprendimiento.sections.emprendimientos.mainDev.img.alt} />
                                    <h4 className="logo-caption">{mockRealEstateEmprendimiento.sections.emprendimientos.mainDev.subtitle}</h4>
                                </> 
                            :   <h4>{mockRealEstate.sections.home.mainDev.location}</h4>}
                                <p className={location.pathname.includes('/emprendimientos') ? "text-content" : ""}>
                                    {location.pathname.includes('/emprendimientos') 
                                    ? mockRealEstateEmprendimiento.sections.emprendimientos.mainDev.text
                                    : mockRealEstate.sections.home.mainDev.text}</p>
                            <div class="d-flex align-items-center justify-content-between content-buttons">
                                <Link to={location.pathname.includes('/emprendimientos')
                                    ? mockRealEstateEmprendimiento.sections.emprendimientos.mainDev.link.to
                                    : mockRealEstate.sections.home.mainDev.btn.link} class="btn btn-orange">
                                    {mockRealEstate.sections.home.mainDev.btn.text}
                                    <IconRightSvg class="icon"/></Link>
                                {!location.pathname.includes('/emprendimientos') ?
                                    <Link to={mockRealEstate.sections.home.mainDev.link.to} class="underline">
                                        {mockRealEstate.sections.home.mainDev.link.text}</Link> : '' }
                            </div>       
                        </div>
                    </div>
                    <div class="col-lg-8 px-0 d-flex flex-column main-dev-gallery" id="main-dev-gallery">
                        <OwlCarousel ref={slider}
                            {...options}
                            className="overflow-hidden">
                            {shulbiusDevelopments.map((shulbius, index) => (
                                <div key={index}>
                                    <img style={{objectPosition:"center",objectFit:"cover"}} src={getQuality(getCover(shulbius.photos))} 
                                        alt={shulbius.name} />
                                </div>
                            ))}
                        </OwlCarousel>
                        <div className="arrows align-self-center">
                            <div className="arrow" onClick={() => slider.current.prev()}> {'‹'} </div>
                            <div className="arrow" onClick={() => slider.current.next()}> {'›'} </div>
                        </div>
                    </div>
                </div>
                { location.pathname.includes('/emprendimientos') ? 
                    <a to="#featured-devs" class="goto" 
                        onClick={() => scrollToElement("section.development-about")}>
                        <span>
                            <IconRightSvg class="icon"/>
                        </span>
                    </a> : '' }
            </div>
        </section>
    )
}

export default connect(state => ({
    properties: state.properties.featured,
    developments: state.developments.developments,
    loading: state.developments.loading,
    api_key:state.settings.keys.tokko
}),null)(MainDevelopment);